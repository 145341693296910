<template>
  <div class="container">
    <h1 class="title">Виды спорта</h1>

    <AddButton @click="handleAdd" />

    <b-table
      :data="sports"
      :default-sort-direction="defaultSortDirection"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      :mobile-cards="false"
    >
      <b-table-column v-slot="props" field="id" label="#">
        {{ props.index + 1 }}
      </b-table-column>
      <b-table-column v-slot="props" field="Title" label="Название" sortable>
        <router-link
          :to="{ name: 'SportShow', params: { id: props.row.id } }"
          exact
        >
          {{ props.row.Title }}
        </router-link>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="internationalFederation.title"
        label="Международная федерация"
        sortable
      >
        {{
          props.row.international_federation
            ? props.row.international_federation.title
            : ""
        }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import SportForm from "./SportForm";
import { mapGetters } from "vuex";
import AddButton from "@/common/components/AddButton";
import { Resource } from "@/common/const/common";

export default {
  name: "SportsIndex",

  components: { AddButton },

  data() {
    return {
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      sports: [],
    };
  },

  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
  },

  async mounted() {
    await this.loadData();
    document.title = "Виды спорта";
  },
  methods: {
    async loadData() {
      try {
        this.sports = await this.$api[Resource.SPORTS].get(`?_sort=Title:asc`);
      } catch (e) {
        console.log(e);
        await this.$router.push("/not-found");
      }
    },

    async closeForm(isAdded = true) {
      if (isAdded) {
        await this.loadData();
      }
    },

    async handleAdd() {
      this.$buefy.modal.open({
        parent: this,
        component: SportForm,
        props: { sport: { Title: "", international_federation: null } },
        events: { close: this.closeForm },
        hasModalCard: true,
      });
    },
  },
};
</script>
